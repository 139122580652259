import CashbackProducts from './features/Cashback/CashbackProducts';
import CashbackShops from './features/Cashback/CashbackShops';
import CashbackExtension from './features/CashbackExtension/CashbackExtension';
import CashbackReclamation from './features/CashbackReclamation/CashbackReclamation';
import ClickoutHint from './features/ClickoutHint/ClickoutHint';
import Cashouts from './features/Dashboard/Cashouts';
import ChangeCashoutCredentials from './features/Dashboard/ChangeCashoutCredentials';
import ChangeCredentials from './features/Dashboard/ChangeCredentials';
import ChangeInterests from './features/Dashboard/ChangeInterests';
import Complaints from './features/Dashboard/Complaints';
import CreateComplaint from './features/Dashboard/CreateComplaint';
import Dashboard from './features/Dashboard/Dashboard';
import Settings from './features/Dashboard/Settings';
import Transactions from './features/Dashboard/Transactions';
import DataProtection from './features/DataProtection/DataProtection';
import Disclaimer from './features/Disclaimer/Disclaimer';
import DocumentsAndFormulars from './features/DocumentsAndFormulars/DocumentsAndFormulars';
import Faq from './features/Faq/Faq';
import Imprint from './features/Imprint/Imprint';
import Login from './features/Login';
import Logout from './features/Logout';
import Special from './features/Special/Special';
import SsoLogin from './features/SsoLogin';
import Terms from './features/Terms/Terms';
import Tickets from './features/Tickets/Tickets';
import Travel from './features/Travel/Travel';
import TravelBooking from './features/Travel/TravelBooking';
import Registration from './features/Registration/Registration';
import ResetPassword from './features/ResetPassword';

export interface Route {
  title: string;
  link: string;
  component?: any;
  infoPage?: { route: string; content: any };
  private?: boolean;
}

export const navigationItems: Route[] = [
  // {
  //   title: 'Cashback-Highlights',
  //   link: '/angebote',
  //   component: Special,
  // },
  // {
  //   title: 'Shopping',
  //   link: '/cashback/shops',
  //   component: CashbackShops,
  // },
  // {
  //   title: 'Reisen',
  //   link: '/reisen',
  //   component: Travel,
  // },
  // {
  //   title: 'Tickets',
  //   link: '/tickets',
  //   component: Tickets,
  // },
  // {
  //   title: 'Bonusradar',
  //   link: '/bonusradar',
  //   component: CashbackExtension,
  // },
];

export const footerItems: Route[] = [
  {
    title: 'FAQ',
    link: '/faq',
    component: Faq,
  },
  {
    title: 'Impressum',
    link: '/impressum',
    component: Imprint,
  },
  {
    title: 'Haftungsausschluss',
    link: '/haftungsausschluss',
    component: Disclaimer,
  },
  {
    title: 'Datenschutz',
    link: '/datenschutz',
    component: DataProtection,
  },
  {
    title: 'AGB',
    link: '/agb',
    component: Terms,
  },
];

export const subNavigationItems: Route[] = [
  {
    title: 'Dashboard',
    link: '/mein-konto',
    component: Dashboard,
    private: true,
  },
  {
    title: 'Gesamtübersicht',
    link: '/gesamtuebersicht',
    component: Transactions,
    private: true,
  },
  {
    title: 'Nachbuchungsanfragen',
    link: '/nachbuchungsanfragen',
    component: Complaints,
    private: true,
  },
  {
    title: 'Meine Daten',
    link: '/meine-daten',
    component: Settings,
    private: true,
  },
];

export const hiddenRoutes: Route[] = [
  {
    title: 'Reklamation',
    link: '/gesamtuebersicht/reklamation',
    component: CashbackReclamation,
    private: true,
  },
  {
    title: 'Logout',
    link: '/abmelden',
    component: Logout,
    private: true,
  },
  {
    title: 'Login',
    link: '/login',
    component: SsoLogin,
  },
  {
    title: 'Mehrwerk Login',
    link: '/mehrwerk-login',
    component: Login,
  },
  {
    title: 'Dokumente & Formulare',
    link: '/dokumente-und-formulare',
    component: DocumentsAndFormulars,
    private: true,
  },
  {
    title: 'Auszahlungen',
    link: '/auszahlungen',
    component: Cashouts,
    private: true,
  },
  {
    title: 'Registrierung',
    link: '/registrierung',
    component: Registration,
  },
  {
    title: 'Neue Nachbuchungsanfrage',
    link: '/nachbuchungsanfragen/neue-nachbuchungsanfrage',
    component: CreateComplaint,
    private: true,
  },
  {
    title: 'persoenliche-angaben',
    link: '/meine-daten/persoenliche-angaben',
    component: ChangeCredentials,
    private: true,
  },
  {
    title: 'meine-auszahlungseinstellungen',
    link: '/meine-daten/meine-auszahlungseinstellungen',
    component: ChangeCashoutCredentials,
    private: true,
  },
  {
    title: 'ihre-interessen',
    link: '/ihre-interessen',
    component: ChangeInterests,
    private: true,
  },
  {
    title: 'Passwort zurücksetzen',
    link: '/passwort-vergessen',
    component: ResetPassword,
  },
  {
    title: 'CashbackProducts',
    link: '/cashback/produkte',
    component: CashbackProducts,
  },
  {
    title: 'Reisebuchung',
    link: '/reise/buchung',
    component: TravelBooking,
  },
  {
    title: 'Clickout-Hinweis',
    link: '/clickout',
    component: ClickoutHint,
  },
];
